var _api_root = '/api/';
// _api_root = "https://xh-h5.73lt.com/api/";
// _api_root = '/api/';

var api = {
    get: {
        singleSreach: _api_root + `statistics/front/singleSreach`,
        eventRanksInfo: _api_root + `statistics/front/eventRanksInfo`,
        teamScores: _api_root + `statistics/front/pageTeamScores`,
        singleScores: _api_root + `statistics/front/pageSingleScores`,
        frontSearchByEventId: _api_root + `event-mgr/eventParticipantList/frontSearchByEventId`,
        certInfo: _api_root + `statistics/front/certInfo`,
        getEvent: _api_root + `statistics/front/getEvent`,
        getProject: _api_root + `statistics/front/getProject`,
        singleScoresPage: _api_root + `statistics/front/singleScoresPage`,
        teamScoresPage: _api_root + `statistics/front/teamScoresPage`,
        largeScreenDetail: _api_root + `event-mgr/largeScreen/detail`,
        listLargeScreenRanksData: _api_root + `statistics/front/listLargeScreenRanksData`,
        listIndividualScoresListByTeamId: _api_root + `statistics/front/getIndividualScoresListByTeamId`,
        certInfoShow: _api_root + `statistics/front/certInfoShow`,
        frontSearch: _api_root + `event-mgr/eventParticipantList/frontSearch`,
        getCurrentProjectId: _api_root + `event-mgr/eventParticipantList/getCurrentProjectId`,
        certPreview: _api_root + `statistics/front/certInfo/preview`,
        getIndividualScoresByBibNumbers: _api_root + `statistics/front/getIndividualScoresByBibNumbers`,
        getTeamScoresByTeamIds: _api_root + `statistics/front/getTeamScoresByTeamIds`,
        getIndividualScoresById: _api_root + `statistics/front/getIndividualScoresById`,
        eventList: _api_root + `event-mgr/event/frontPage`,
        projectList: _api_root + `event-mgr/front/eventProject/list`,
        getPointMonitor: _api_root + `statistics/front/checkpoint/monitor`,
        getNotPass: _api_root + `statistics/front/checkpoint/notPass`,
        getClose: _api_root + `statistics/front/checkpoint/close`,
        electronList: _api_root + `statistics/front/electron`,
        roleCertList: _api_root + `statistics/front/roleCert`,
        downNotPass: _api_root + `statistics/front/checkpoint/downNotPass`,
        downClose: _api_root + `statistics/front/checkpoint/downClose`,
    },
    post: {
        // postLogin: _api_root + "api/v1/auth/login",
    },
    api_root: _api_root

};
export default api;
