<template>
  <div class="body">
    <div v-if="event.status == 3 || event.status == 2  || inner || type !=1">
      <div class="title">{{ type == 2 ? '电子号码布' : (type == 3 ? '证书下载' : '成绩查询') }}</div>
      <div class="sreach">
        <i class="el-icon-search icon"></i>

        <el-input class="input" v-model="keyword" :placeholder="sreachRule"></el-input>
        <el-button class="btn" type="primary" @click="sreach">搜索</el-button>
      </div>
      <div style="width: 60%;margin: auto" v-if="isSreach">
        <div class="form">
          <el-form :inline="true" class="demo-form-inline">
            <el-row>
              <el-col :span="6">
                <div class="grid-content bg-purple-light">
                  <el-form-item label="项目">
                    <el-select v-model="projectId" placeholder="请选择团队" @change="projectChange">
                      <!--                      <el-option :label="item.projectName" :value="item.projectId"-->
                      <!--                                 v-for="item in projectList"></el-option>-->

                      <template v-for="item in projectList">
                        <el-option :label="item.projectName" :value="item.projectId"
                                   v-if="item.eventRanksList"></el-option>
                      </template>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <el-form-item label="榜单">
                    <el-select v-model="eventRanksId" placeholder="请选择榜单" @change="eventRanksChange">
                      <el-option :label="item.name" :value="item.id" v-for="item in ranksList"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div v-if="currentRanks.nature == 1">
          <el-table
              stripe
              :header-cell-style="{background:'#34C5F6', color:'white', 'line-height':'48px'}"
              :row-style="{ 'text-align': 'center'}"
              class="table"
              :data="tableData"
              style="width: 100%"
              :key="1"
          >
            <el-table-column align="center" header-align="center" label="排名" prop="index" :formatter="indexFormat"
                             v-if="showContents.indexOf('1')>=0"></el-table-column>
            <el-table-column align="center" header-align="center" label="团队名称" prop="teamName"></el-table-column>
            <el-table-column align="center" header-align="center" label="枪声成绩" prop="eventScore"
                             :formatter="eventScoreFormat" v-if="showContents.indexOf('7')>=0"></el-table-column>
            <el-table-column align="center" header-align="center" label="净成绩" prop="netScore"
                             :formatter="netScoreFormat" v-if="showContents.indexOf('10')>=0"></el-table-column>
            <el-table-column prop="currentLap" label="圈数"
                             v-if="showContents.indexOf('3')>=0  && sportRule"></el-table-column>
            <el-table-column prop="currentDistance" label="里程"
                             v-if="showContents.indexOf('3')>=0  && !sportRule"></el-table-column>
            <el-table-column prop="reduceTime" :formatter="timeReductionFormat" label="减时"
                             v-if="showContents.indexOf('9')>=0"></el-table-column>
            <el-table-column prop="penaltyTime" :formatter="timePenaltyFormat" label="罚时"
                             v-if="showContents.indexOf('5')>=0"></el-table-column>
            <!--            <el-table-column prop="status" label="状态" :formatter="statusFormat"-->
            <!--                             v-if="showContents.indexOf('6')>=0"></el-table-column>-->
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table v-show="scope.row.individualScores && scope.row.individualScores.length>0" border
                          :header-cell-style="{background:'#eef1f6',color:'#606266'}" :data="scope.row.individualScores"
                          style="width:100%">

                  <el-table-column type="index" width="60" label="序号"
                                   v-if="showContents.indexOf('1')>=0"></el-table-column>
                  <el-table-column prop="bibNumber" label="号码牌"></el-table-column>
                  <el-table-column prop="fullName" label="姓名"></el-table-column>
                  <el-table-column prop="timeSpeed" label="时速" :formatter="formatterTimeSpeed"
                                   v-if="showContents.indexOf('11')>=0">
                  </el-table-column>
                  <el-table-column prop="country" label="国籍" v-if="showContents.indexOf('12')>=0"></el-table-column>
                  <el-table-column prop="age" label="年龄" v-if="showContents.indexOf('2')>=0"></el-table-column>
                  <el-table-column prop="currentLap" label="圈数"
                                   v-if="showContents.indexOf('3')>=0  && sportRule">
                  </el-table-column>
                  <el-table-column prop="currentDistance" label="里程"
                                   v-if="showContents.indexOf('3')>=0  && !sportRule">
                  </el-table-column>
                  <el-table-column prop="speed" label="配速" :formatter="formatterSpeed"
                                   v-if="showContents.indexOf('4')>=0"></el-table-column>
                  <el-table-column prop="timeReduction" :formatter="timeReductionFormat" label="减时"
                                   v-if="showContents.indexOf('9')>=0"></el-table-column>
                  <el-table-column prop="timePenalty" :formatter="timePenaltyFormat" label="罚时"
                                   v-if="showContents.indexOf('5')>=0"></el-table-column>
                  <el-table-column label="枪声成绩" prop="eventScore"
                                   :formatter="eventScoreFormat" v-if="showContents.indexOf('7')>=0">
                  </el-table-column>
                  <el-table-column label="净成绩" prop="netScore"
                                   :formatter="netScoreFormat" v-if="showContents.indexOf('10')>=0">
                  </el-table-column>
                  <el-table-column prop="status" label="状态" :formatter="statusFormat"
                                   v-if="showContents.indexOf('6')>=0">
                  </el-table-column>
                  <el-table-column
                      fixed="right"
                      label="操作"
                      width="100">
                    <template slot-scope="scope">
                      <el-button @click="toScore(scope.row)" type="text" size="small">查看</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>

            </el-table-column>
          </el-table>
        </div>
        <div v-else>
          <el-table
              :key="2"
              stripe
              :header-cell-style="{background:'#34C5F6', color:'white', 'line-height':'48px'}"
              :row-style="{ 'text-align': 'center'}"
              class="table"
              :data="tableData"

              style="width: 100%"
          >
            <el-table-column prop="index" :formatter="formatterIndex" width="60" label="序号"
                             v-if="showContents.indexOf('1')>=0"></el-table-column>
            <el-table-column prop="bibNumber" label="号码牌"></el-table-column>
            <el-table-column prop="fullName" label="姓名"></el-table-column>
            <el-table-column prop="timeSpeed" label="时速" :formatter="formatterTimeSpeed"
                             v-if="showContents.indexOf('11')>=0">
            </el-table-column>
            <el-table-column prop="country" label="国籍" v-if="showContents.indexOf('12')>=0"></el-table-column>
            <el-table-column prop="age" label="年龄" v-if="showContents.indexOf('2')>=0"></el-table-column>
            <el-table-column prop="currentLap" label="圈数"
                             v-if="showContents.indexOf('3')>=0 && sportRule">
            </el-table-column>
            <el-table-column prop="currentDistance" label="里程"
                             v-if="showContents.indexOf('3')>=0 &&!sportRule">
            </el-table-column>
            <el-table-column prop="speed" label="配速" :formatter="formatterSpeed"
                             v-if="showContents.indexOf('4')>=0"></el-table-column>
            <el-table-column prop="timeReduction" :formatter="timeReductionFormat" label="减时"
                             v-if="showContents.indexOf('9')>=0"></el-table-column>
            <el-table-column prop="timePenalty" :formatter="timePenaltyFormat" label="罚时"
                             v-if="showContents.indexOf('5')>=0"></el-table-column>
            <el-table-column label="枪声成绩" prop="eventScore"
                             :formatter="eventScoreFormat" v-if="showContents.indexOf('7')>=0">
            </el-table-column>
            <el-table-column label="净成绩" prop="netScore"
                             :formatter="netScoreFormat" v-if="showContents.indexOf('10')>=0">
            </el-table-column>

            <el-table-column prop="status" label="状态" :formatter="statusFormat"
                             v-if="showContents.indexOf('6')>=0">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="100">
              <template slot-scope="scope">
                <el-button @click="toScore(scope.row)" type="text" size="small">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div v-else class="no-public">
      没有发布
    </div>
  </div>
</template>

<script>
import {
  getEvent,
  frontSearch,
  getIndividualScoresByBibNumbers,
  getTeamScoresByTeamIds
} from '@/utils/request';
import {timeFilter} from '@/utils/dateUtil';

export default {
  name: 'Index',
  data() {
    return {
      keyword: '',
      eventRanksId: '',
      inner: false,
      isSreach: false,
      event: {},
      type: 1,
      projectId: '',
      eventId: '',
      tableData: [],
      projectList: []
    }
  },

  mounted() {
    this.eventId = this.$route.query.eventId
    if (!this.eventId) {
      this.$router.push({
        path: '/pages/event/list', query: {}
      })
      return
    }
    let that = this;
    if (this.$route.query.electron) {
      this.type = 2;
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    } else {
      if (this.$route.query.inner == 1) {
        this.inner = true
      }
    }

    getEvent(this.eventId).then(
        (res) => {
          if (!res.data.successful) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
            return
          }
          that.event = res.data.data;
          document.title = that.event.eventName;
          if (this.type == 1) {
            if (!(that.event.status == 3 || that.event.status == 2) && !that.inner) {
              this.$router.push({
                path: '/pages/event/list', query: {}
              })
            }

            if (that.event.publis == 2) {
              this.$router.push({
                path: '/pages/score/rank/single', query: {
                  eventId: this.eventId,
                  inner: this.$route.query.inner
                }
              })
            }
          }
        },
        (err) => {
          console.log(err);
        }
    )
  },
  filters: {},
  computed: {
    sportRule() {
      let boo = false;
      this.projectList.forEach(item => {
        if (item.projectId == this.projectId) {
          boo = item.sportRule == 2;
        }
      })
      return boo;
    },
    currentRanks() {
      let result = {
        nature: 2
      };
      this.ranksList.forEach(item => {
        if (item.id == this.eventRanksId) {
          result = item;
        }
      })
      return result
    },
    showContents() {
      if (!this.ranksList || !this.eventRanksId) {
        return [];
      }
      let content = []
      this.ranksList.forEach(item => {
        if (item.id == this.eventRanksId) {
          content = item.content.split(',');
        }
      })
      return content;
    },
    sreachRule() {
      let arr = null;
      if (this.type == 1 && this.event.sreachRule) {
        arr = this.event.sreachRule.split(',')
      } else if (this.type == 2) {
        arr = this.event.electronSreachRule
      } else {
        arr = this.event.roleCertSreachRule
      }
      if (!arr) {
        return ''
      }
      const result = [];
      if (arr.indexOf('1') >= 0) {
        result.push('参赛号');
      }
      if (arr.indexOf('2') >= 0) {
        result.push('姓名');
      }
      if (arr.indexOf('3') >= 0) {
        result.push('手机号');
      }
      if (arr.indexOf('4') >= 0) {
        result.push('身份证');
      }
      return '请输入' + result.join('/');
    },
    ranksList() {
      let arr = [];
      this.projectList.forEach(item => {
        if (item.projectId == this.projectId) {
          arr = item.eventRanksList;
        }
      })
      return arr
    },
    bibNumbers() {
      let bibNumbers = [];
      this.projectList.forEach(item => {
        if (item.projectId == this.projectId) {
          bibNumbers = item.bibNumbers;
        }
      })
      return bibNumbers
    },
    teamIds() {
      let teamIds = [];
      this.projectList.forEach(item => {
        if (item.projectId == this.projectId) {
          teamIds = item.teamIds;
        }
      })
      return teamIds
    }
  },
  methods: {
    indexFormat(row, column) {
      return row.index + 1;
    },
    formatterIndex(row, column) {
      return row.index + 1;
    },
    formatterTimeSpeed(row, column) {
      if (row.currentDistance <= 0 || row.netScore <= 0) {
        return '--';
      }
      return (row.currentDistance / (row.netScore / (1000 * 60 * 60))).toFixed(2);
    },
    formatterSpeed(row, column) {
      if (row.currentDistance <= 0 || row.netScore <= 0) {
        return '--';
      }
      const seconds = parseInt(row.netScore / 1000 / row.currentDistance);
      return this.timeFilter2(seconds);
    },
    timeReductionFormat(row, column) {
      return timeFilter(row.netScore)
    },
    timePenaltyFormat(row, column) {
      return timeFilter(row.netScore)
    },
    projectChange(v) {
      this.eventRanksId = this.ranksList[0].id;
      this.loadIndividualScores()
    },
    eventRanksChange(v) {
      this.loadIndividualScores()
    },
    statusFormat(row, column) {
      if (row.status == 0) {
        return 'DNS';
      }
      if (row.status == 8) {
        return 'DNF';
      }
      if (row.status == 9) {
        return row.currentCheckpoint ? row.currentCheckpoint : '比赛中'
      }
      if (row.status == 10) {
        return '已完赛';
      }
      if (row.status == 7) {
        return '已退赛';
      }
      if (row.status == 6) {
        return '无效';
      }
      return ''
    },
    netScoreFormat(row, column) {
      return timeFilter(row.netScore)
    },
    eventScoreFormat(row, column) {
      return timeFilter(row.eventScore)
    },
    loadIndividualScores() {
      if (!this.ranksList || this.ranksList.lenght <= 0) {
        return
      }
      let that = this;
      if (this.currentRanks.nature == 1) {
        getTeamScoresByTeamIds({
          eventRanksId: this.eventRanksId,
          teamIds: this.teamIds.join(',')
        }).then(
            (res) => {
              if (res.data && res.data.code == 200) {
                that.tableData = res.data.data;
              } else {
                this.$message({
                  message: '服务器繁忙',
                  type: 'warning'
                });
              }
            },
            (err) => {
              console.log(err);
            }
        )
      } else {
        getIndividualScoresByBibNumbers({
          eventRanksId: this.eventRanksId,
          bibNumbers: this.bibNumbers.join(',')
        }).then(
            (res) => {
              if (res.data && res.data.code == 200) {
                that.tableData = res.data.data;
              } else {
                this.$message({
                  message: '服务器繁忙',
                  type: 'warning'
                });
              }
            },
            (err) => {
              console.log(err);
            }
        )
      }


    },
    sreach() {
      if (!this.keyword) {
        this.$message({
          message: '请输入关键字',
          type: 'warning'
        });
        return
      }
      if (this.type == 2) {
        this.$router.push({
          path: '/pages/electron/electron', query: {
            eventId: this.eventId,
            keyword: this.keyword
          }
        })
      }

      if (this.type == 3) {
        this.$router.push({
          path: '/pages/role-cert/role-cert', query: {
            eventId: this.eventId,
            keyword: this.keyword
          }
        })
      }
      let that = this;
      console.log('this.event.sreachRule', this.event.sreachRule)
      frontSearch({
        eventId: this.eventId, keyword: this.keyword, sreachRule: this.event.sreachRule
      }).then(
          (res) => {
            if (res.data && res.data.code == 200) {
              that.projectList = res.data.data;
              that.isSreach = true;
              if (that.projectList.length > 0) {
                that.projectId = that.projectList[0].projectId;
                if (that.ranksList.length > 0) {
                  that.eventRanksId = that.ranksList[0].id;
                  that.loadIndividualScores();
                }
              }
            } else {
              this.$message({
                message: '服务器繁忙',
                type: 'warning'
              });
            }
          },
          (err) => {
            console.log(err);
          }
      )
    },

    toScore(row, event, column) {
      this.$router.push({
        path: '/pages/score/single/single', query: {
          bibNumber: row.bibNumber,
          eventRanksId: this.eventRanksId
        }
      })
    },

  }
}
</script>

<style scoped lang="scss">
.body {
  width: 100%;
  height: 100%;
  background-image: url('../assets/sreach_bg.png');
  background-size: cover; /* 使图片平铺满整个浏览器（从宽和高的最大需求方面来满足，会使某些部分无法显示在区域中） */
  position: absolute; /* 不可缺少 */
  z-index: -1;
  background-repeat: no-repeat;

  .title {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 56px;
    margin-top: 200px;
  }

  .sreach {
    display: flex;
    width: 613px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 2px solid #34C5F6;
    margin: auto;
    margin-top: 24px;

    .icon {
      line-height: 50px;
      margin-left: 20px;
    }

    .sreach-input {
      border: none;
      margin-top: 4px;

    }

    .btn {
      width: 87px;
      height: 42px;
      background: #34C5F6;
      border-radius: 4px;
      border: none;
      margin-top: 4px;
      margin-right: 4px;
    }
  }

  .form {
    //background: #fff;
    margin-top: 14px;
    padding: 14px 0;

    .sreach-box {
      display: flex;

      .btn {
        background: #34C5F6;
        border: none;
        margin-left: 8px;
      }
    }
  }

  .table {
    margin-top: 14px;
  }

  .header_row {
    display: flex;
    flex-direction: row;
  }

  .addbutton {
    margin-right: 20px;
  }

}

.input {
  border: none;
  line-height: 50px;
}

.input ::v-deep .el-input__inner {
  border: none;
}

.no-public {
  padding-top: 200px;
}

</style>
