import axios from 'axios'
import api from './api.js'

//创建一个axios的对象
const instance = axios.create({
    // baseURL: "http://localhost:8080", //baseURL会在发送请求时拼接在url参数前面
    timeout: 5000
})

//请求拦截
//所有的网络请求都会先走这个方法，我们可以在他里面为请求添加一些自定义的内容
instance.interceptors.request.use(
    function (config) {
        //token是跟服务器约定好的
        // config.headers.token = "123456";
        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);

//响应拦截
//所有的网络请求返回数据之后都会先执行此方法
//此处可以根据服务器的返回状态码做相应的数据
instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (err) {
        return Promise.reject(err);
    }
);

export function singleSreach(eventRanksId, keyword) {
    return instance.get(api.get.singleSreach + '/' + eventRanksId + '/' + keyword);
}

export function getIndividualScoresById(params) {
    return instance.get(api.get.getIndividualScoresById, {
        params
    });
}

export function certInfo(projectId, bibNumber) {
    return instance.get(api.get.certInfo + '/' + projectId + '/' + bibNumber);
}

export function eventRanksInfo(eventRanksId) {
    return instance.get(api.get.eventRanksInfo + '/' + eventRanksId);
}

export function teamScores(eventRanksId, params) {
    return instance.get(api.get.teamScores + '/' + eventRanksId, {
        params
    });
}

export function singleScores(eventRanksId, params) {
    return instance.get(api.get.singleScores + '/' + eventRanksId, {
        params
    });
}

export function frontSearchByEventId(params) {
    return instance.get(api.get.frontSearchByEventId, {
        params
    });
}

export function getEvent(eventId) {
    return instance.get(api.get.getEvent + '/' + eventId);
}

export function getProject(projectId) {
    return instance.get(api.get.getProject + '/' + projectId);
}


export function singleScoresPage(eventRanksId, params) {
    return instance.get(api.get.singleScoresPage + '/' + eventRanksId, {
        params
    });
}

export function teamScoresPage(eventRanksId, params) {
    return instance.get(api.get.teamScoresPage + '/' + eventRanksId, {
        params
    });
}

export function largeScreenDetail(id) {
    return instance.get(api.get.largeScreenDetail, {
        params: {
            id
        }
    });
}

export function listLargeScreenRanksData(params) {
    return instance.get(api.get.listLargeScreenRanksData, {
        params
    });
}

export function listIndividualScoresListByTeamId(eventRanksId, teamId) {
    return instance.get(api.get.listIndividualScoresListByTeamId + '/' + eventRanksId + '/' + teamId, {});
}

export function certInfoShow(projectId, bibNumber) {
    return instance.get(api.get.certInfoShow + '/' + projectId + '/' + bibNumber, {});
}

export function frontSearch(params) {
    return instance.get(api.get.frontSearch, {
        params
    });
}

export function getCurrentProjectId(params) {
    return instance.get(api.get.getCurrentProjectId, {
        params
    });
}

export function certPreview(params) {
    return instance.get(api.get.certPreview, {
        params
    });
}

export function getIndividualScoresByBibNumbers(params) {
    return instance.get(api.get.getIndividualScoresByBibNumbers, {
        params
    });
}

export function getTeamScoresByTeamIds(params) {
    return instance.get(api.get.getTeamScoresByTeamIds, {
        params
    });
}


export function eventList(params) {
    return instance.get(api.get.eventList, {
        params
    });
}

export function projectList(params) {
    return instance.get(api.get.projectList, {
        params
    });
}

export function getPointMonitor(params) {
    return instance.get(api.get.getPointMonitor, {
        params
    });
}

export function getNotPass(params) {
    return instance.get(api.get.getNotPass, {
        params
    });
}

export function getClose(params) {
    return instance.get(api.get.getClose, {
        params
    });
}


export function electronList(eventId, keyword) {
    return instance.get(api.get.electronList + '/' + eventId + '/' + keyword);
}

export function roleCertList(eventId, keyword) {
    return instance.get(api.get.roleCertList + '/' + eventId + '/' + keyword);
}
